<template>
    <div id="chartAge"></div>
</template>

<script>
import * as echarts from 'echarts';

let myChart;

export default {
    name: "ageC",
    props:{
        data:{
            type:Array,
            default(){
                return []
            }
        }
    },
    data(){
        return{
            currentIndex:0
        }
    },
    mounted() {
        this.$nextTick(function (){
            this.createChart();
        })
    },
    watch:{
        data(){
            this.createChart();
        }
    },
    methods:{
        createChart(){
            const chartDom = document.getElementById('chartAge');
            myChart = echarts.init(chartDom, 'dark');
            // echarts.registerMap('HK', geoJson);
            const option = {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'item',
                    formatter: '用户数量 <br/>{b} : {c}位 (占比{d}%)'
                },
                legend: {
                    top: 'bottom',
                    show:false
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: false },
                        dataView: { show: false, readOnly: false },
                        restore: { show: false },
                        saveAsImage: { show: false }
                    }
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: [30, 120],
                        center: ['50%', '50%'],
                        roseType: 'area',
                        itemStyle: {
                            borderRadius: 8
                        },
                        data: this.data
                    }
                ]
            };

            myChart.setOption(option);

            let that = this;

            if(!this.timer){
                this.timer = setInterval(function (){
                    that.changeDataIndex();
                },3000)
            }


        },
        changeDataIndex(){


            let dataLen = this.data.length;
            if (dataLen > 0) {
                myChart.dispatchAction({
                    type: 'showTip',  // 提示框
                    seriesIndex: 0,
                    dataIndex: this.currentIndex // 该行柱子高亮
                });
                // myChart.dispatchAction({
                //     type: 'highlight',
                //     seriesIndex: 0,
                //     dataIndex: this.currentIndex // 该行柱子高亮
                // })
                this.currentIndex = (this.currentIndex + 1) % dataLen;
            }


        }
    },

}
</script>

<style scoped>

</style>
