<template>
    <div>
        <div class="title">日活用户</div>
        <div class="imgs">
            <img class="scroll" src="../../assets/images/user1.png" >
            <img src="../../assets/images/user2.png" >
        </div>

        <div class="number">{{ number }}</div>
    </div>
</template>

<script>
export default {
    name: "userNumber",
    props:{
        number:{
            type:Number,
            default:3858
        }
    }
}
</script>

<style lang="scss" scoped>

    .title{
        width: 100px; line-height: 40px;  height: 40px;
        border: 1px solid #FFF; font-size: 18px;
        text-align: center; margin: 0 auto; margin-top: 10px;
    }
    .imgs{
        position: relative; margin: 20px auto; width: 100px; height: 100px;
        img{
            position: absolute;
            left: 0; top: 0;
        }
        .scroll{

            animation: rotateBackground .8s linear infinite; /* 动画名称 持续时间 速率 重复次数 */
        }
    }

    .number{
        font-size: 42px;
    }
</style>
