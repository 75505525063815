<template>
    <div id="drugChart"></div>
</template>

<script>
import * as echarts from 'echarts';

let myChart;

export default {
    name: "drugC",
    props:{
        data:{
            type:Array,
            default(){
                return [];
            }
        }
    },
    data(){
        return{
            currentIndex:0
        }
    },
    watch:{
        data(){
            this.createChart();
        }
    },
    mounted() {
        this.$nextTick(function (){
            this.createChart();
        })
    },
    methods:{
        createChart(){
            const chartDom = document.getElementById('drugChart');
            myChart = echarts.init(chartDom, 'dark');
            // echarts.registerMap('HK', geoJson);
            let option = {
                backgroundColor: 'transparent',
                // title: {
                //     show:false,
                //     text: 'Referer of a Website',
                //     subtext: 'Fake Data',
                //     left: 'center'
                // },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    show:false,
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: '确诊数量',
                        type: 'pie',
                        radius: '50%',
                        data: this.data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };

            myChart.setOption(option);

            let that = this;

            if (!this.timer){
                this.timer = setInterval(function (){
                    that.changeDataIndex();
                },3000)
            }

        },
        changeDataIndex(){


            let dataLen = this.data.length;
            if (dataLen > 0) {
                myChart.dispatchAction({
                    type: 'showTip',  // 提示框
                    seriesIndex: 0,
                    dataIndex: this.currentIndex // 该行柱子高亮
                });
                // myChart.dispatchAction({
                //     type: 'highlight',
                //     seriesIndex: 0,
                //     dataIndex: this.currentIndex // 该行柱子高亮
                // })
                this.currentIndex = (this.currentIndex + 1) % dataLen;
            }


        }

    },

}
</script>

<style scoped>

</style>
