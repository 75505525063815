<template>
    <div id="app">
       <!-- <P4KIndex style="width:6144px;height:2560px;transform: scale(0.5);position:fixed;left:-1536px;top:-640px;"/> -->

       <P4KIndex style="width:3072px;height:1280px; position:fixed;left:0;top:0;" v-if="screenType === 1" />
       <PMobile v-if="screenType === 2"></PMobile>

    </div>
</template>

<script>
import P4KIndex from './pages/index.vue'
import PMobile from './pages/mobile.vue'

export default {
    name: 'App',
    components: {
        P4KIndex, PMobile
    },
    computed: {
        screenType() {
            console.log('🚀🚀',this.getRouteType());
            //1: PC, 2: Mobile 地址后面乱输入也会显示PC
            if (!this.getRouteType()) {
                return 1;
            } else if (this.getRouteType().type == 2) {
                return 2;
            } else {
                return 1
            }
        }
    },
    methods: {

        getRouteType: function () {
            var url = window.document.location.href.toString();
            var u = url.split("?");
            if (typeof (u[1]) == "string") {
                u = u[1].split("&");
                var get = {};
                for (var i in u) {
                    var j = u[i].split("=");
                    get[j[0]] = j[1];
                }
                return get;
            } else {
                return false;
            }
        }
    }
}
</script>

<style>
body{
    background: #042546;
}
*{
    margin: 0; padding: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #FFF;
    margin: 0; padding: 0;
}

@keyframes rotateBackground {
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(100deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@keyframes blink  {
    0%{
        opacity: 1;
    }
    5%{
        opacity: 0.7;
    }
    10%{
        opacity: 1;
    }
    15%{
        opacity: 0.7;
    }
    20%{
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

</style>
