<template>
    <div class="sumCount">
        <div class="en">DETECTED</div>
        <div class="en">QUANTITY</div>
        <div class="cn">总检测量</div>
        <div class="num">{{ reportNumber }}</div>
        <img src="../../assets/images/ReagentType.png" />
    </div>
</template>

<script>
export default {
    name: "sumCount",
    props:{
        reportNumber:{
            type:Number,
            default:0
        }
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">
.sumCount{
    border: 4px solid transparent;
    border-radius: 16px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom right, #031441, #032544), linear-gradient(180deg, #0063c9, #760083);
    margin-left: 30px;
    padding: 15px;
    .en{
        font-size: 17px; text-align: left;
    }
    .cn{
        font-size: 18px; text-align: left; margin-top: 20px;
    }
    .num{
        font-size: 30px; text-align: left;margin-top: 20px;
    }
    img{
        float: right;margin-top: 10px;
    }

}
</style>
