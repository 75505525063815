import { render, staticRenderFns } from "./reagentType.vue?vue&type=template&id=1fb47fdb&scoped=true"
import script from "./reagentType.vue?vue&type=script&lang=js"
export * from "./reagentType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fb47fdb",
  null
  
)

export default component.exports