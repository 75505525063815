<template>
    <div id="chart"></div>
</template>

<script>
import * as echarts from 'echarts';

let myChart;

export default {
    name: "reagentType",
    props:{
        data:{
            type:Array,
            default(){
                return []
            }
        }
    },
    watch:{
        data(){
            this.createChart();
        }
    },
    computed:{
        reagentTypeList(){

            let list = this.data.map((item,index)=>[ this.scoreList[index], item.recordCount,item.subName]);
            list.unshift(['score', 'number', 'name']);
            return list;
        }
    },
    data(){
        return{
            currentIndex:0,
            scoreList:[10,30,50,70,90,20,40,60,80,100]
        }
    },
    mounted() {
        this.$nextTick(function (){
            this.createChart();
        })
    },
    methods:{
        getRandomNumber(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        createChart(){
            const chartDom = document.getElementById('chart');
            myChart = echarts.init(chartDom, 'dark');
            // echarts.registerMap('HK', geoJson);
            const option = {
                backgroundColor: 'transparent',
                tooltip: {

                    trigger: 'axis',
                    formatter: function (params) {
                        // params 是一个数组，数组中包含每个系列的数据信息
                        let result = '';
                        params.forEach(function (item) {
                            // item 是每一个系列的数据

                            const value = item.value; // 数据值

                            const marker = item.marker; // 标志图形
                            result += `${marker}${value[2]}检测量: ${value[1]}<br/>`;
                        });
                        return result;
                    },
                    axisPointer: {
                        type: 'shadow',
                        label:{
                            fontSize:20,
                        }
                    }
                },
                dataset: {
                    source: this.reagentTypeList
                },
                grid: { containLabel: true },
                xAxis: { name: '' },
                yAxis: { type: 'category' },
                visualMap: {
                    show:false,
                    orient: 'horizontal',
                    left: 'center',
                    min: 10,
                    max: 100,
                    // text: ['High Score', 'Low Score'],
                    // Map the score column to color
                    dimension: 0,
                    inRange: {
                        color: ['#65B581', '#FFCE34', '#FD665F']
                    }
                },
                series: [
                    {
                        type: 'bar',
                        encode: {
                            // Map the "amount" column to X axis.
                            x: 'number',
                            // Map the "product" column to Y axis
                            y: 'name'
                        }
                    }
                ]
            };

            myChart.setOption(option);

            let that = this;

            if(!this.timer){
                this.timer = setInterval(function (){
                    that.changeDataIndex();
                },3000)
            }

        },
        changeDataIndex(){


            var dataLen = this.data.length;
            if (dataLen > 0) {
                myChart.dispatchAction({
                    type: 'showTip',  // 提示框
                    seriesIndex: 0,
                    dataIndex: this.currentIndex // 该行柱子高亮
                });
                // myChart.dispatchAction({
                //     type: 'highlight',
                //     seriesIndex: 0,
                //     dataIndex: this.currentIndex // 该行柱子高亮
                // })
                this.currentIndex = (this.currentIndex + 1) % dataLen;
            }


        }
    },

}
</script>

<style scoped>

</style>
