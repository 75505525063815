<template>
    <div style="display: flex; justify-content: space-between;">
        <div class="nameList">
            <div v-for="(item,index) in data" :key="'cityUserCount'+index">{{ item.name }} {{ item.py }}</div>
        </div>

        <div id="chartUserCityNumber" style="width: 290px; height: 280px;"></div>
    </div>

</template>

<script>
import * as echarts from 'echarts';

let myChart;

export default {
    name: "userCityNumber",
    data(){
        return{
            currentIndex:0,
        }
    },
    watch:{
        data(){
            this.createChart();
        }
    },
    computed:{
        userNumberList(){
            console.log(this.data.map(item=>item.userNumber))
            return this.data.map(item=>item.userNumber).reverse();
        }
    },
    props:{
        data:{
            type:Array,
            default(){
                return [];
            }
        }
    },
    mounted() {
        this.$nextTick(function (){
            this.createChart();
        })
    },
    methods:{
        createChart(){
            const chartDom = document.getElementById('chartUserCityNumber');
            myChart = echarts.init(chartDom, 'dark');
            // echarts.registerMap('HK', geoJson);
            const option = {
                backgroundColor: 'transparent',
                tooltip: {

                    trigger: 'axis',
                    formatter: function (params) {
                        // params 是一个数组，数组中包含每个系列的数据信息
                        let result = '';
                        params.forEach(function (item) {
                            // item 是每一个系列的数据

                            const value = item.value; // 数据值
                            const marker = item.marker; // 标志图形
                            result += `${marker}日活: ${value}<br/>`;
                        });
                        return result;
                    },
                    axisPointer: {
                        type: 'shadow',
                        label:{
                            fontSize:20,
                        }
                    }
                },
                yAxis: {
                    show: false,
                    type: 'category',
                    data: ['', '', '', '', '']
                },
                xAxis: {
                    show: false,
                    type: 'value'
                },
                series: [
                    {
                        //从data里取出每个的值，组成新数组,并倒序排列
                        data: this.userNumberList,
                        type: 'bar',
                        barWidth: '35%',
                        itemStyle: {
                            emphasis:{

                            },
                            normal: {
                                barBorderRadius: 20,
                                shadowBlur: 3,
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 1, 1,
                                    [
                                        {offset: 0, color: '#7a0083'},
                                        {offset: 1, color: '#0066cc'}
                                    ]
                                ),
                                // label: {
                                //     show: true,
                                //     formatter: function(params) {
                                //         return params.value; // 将标签内容保留两位小数
                                //     }
                                // }
                            },

                        }
                    }
                ]
            };

            myChart.setOption(option);


            let that = this;

            if(!this.timer){
                this.timer = setInterval(function (){
                    that.changeDataIndex();
                },3000)
            }

        },
        changeDataIndex(){


            var dataLen = this.data.length;
            if (dataLen > 0) {
                myChart.dispatchAction({
                    type: 'showTip',  // 提示框
                    seriesIndex: 0,
                    dataIndex: this.currentIndex // 该行柱子高亮
                });
                // myChart.dispatchAction({
                //     type: 'highlight',
                //     seriesIndex: 0,
                //     dataIndex: this.currentIndex // 该行柱子高亮
                // })
                this.currentIndex = (this.currentIndex + 1) % dataLen;
            }


        }

    },

}
</script>

<style lang="scss" scoped>
    .nameList{
        text-align: left; font-size: 14px; margin-top: 60px; line-height: 30px;
        div{
            height: 30px; overflow: hidden; line-break: anywhere;
        }
    }
</style>
