<template>


    <div id="index4k" class="body">
        <div id="bigTitle" :style="{
            zoom:scaleCnter
        }">
            <div class="cn">居测智慧云数字医疗中心</div>
            <div class="en">HOME DETECTION SMART CLOUD DIGITAL MEDICAL CENTER</div>
        </div>
        <div id="centerMap" class="modalBgc" :style="{
            zoom:scaleCnter
        }">
            <div class="mapTitleBg modalBgc">
                全国用户分布图
            </div>
            <BigMap :userMapData="cityUserNumber" :clinic-map-data="cityClinicNumber" :userNumber="userNumber" :clinicNumber="clinicNumber" style="width: 1155px; height: 831px; overflow: hidden; margin: 0 auto"></BigMap>
        </div>
        <div id="left1" class="modalBgc" :style="{
            zoom:scaleLeft,
        }">
            <div class="broadTitle">
                <img src="../assets/images/titleImg.png">
                <div class="cnText">用户数据</div>
                <div class="enText"> / USER DATA</div>
            </div>
            <div class="left1Content">
                <userNumber style="width: 270px;" :number="activeUserNumber"></userNumber>
                <img src="../assets/images/line1.png" style="margin-left: -10px; height: 130px; margin-top: 50px;" />

                <div style="width: 568px;">
                    <div class="title">用户活跃TOP5省份</div>
                    <userCityNumber :data="topFiveProvinceUserNumber" style="width: 400px; height: 280px; margin-top: -40px; margin-left: 50px"></userCityNumber>
                </div>
            </div>
        </div>
       <div id="left2" class="modalBgc" :style="{
            zoom:scaleLeft,
        }">
            <div class="broadTitle">
                <img src="../assets/images/titleImg.png">
                <div class="cnText">用户画像</div>
                <div class="enText"> / USER PORTRAIT</div>
            </div>
            <div class="left2Content">
                <Sex :woman="userSexProportion.womanProportion" :man="userSexProportion.manProportion" style="width: 380px;height: 280px;"></Sex>
                <Age :data="userAgeProportion" style="width: 460px;height: 280px;"></Age>
            </div>

        </div>
         <div id="left3" class="modalBgc" :style="{
            zoom:scaleLeft,
        }">
            <div class="broadTitle">
                <img src="../assets/images/titleImg.png">
                <div class="cnText">检测病种</div>
                <div class="enText"> / Detection of disease</div>
            </div>
            <div class="left3Content">
                <ReagentType :data="reagentTypeReportNumber" style="width: 560px;height: 360px; margin-top: -50px; margin-left: -20px;"></ReagentType>
                <SumCount :reportNumber="reportNumber" style="min-width: 120px; height:  200px;"></SumCount>
            </div>
        </div>

       


        <div id="right1" class="modalBgc" :style="{
            zoom:scaleLeft,
        }">
            <div class="broadTitle">
                <img src="../assets/images/titleImg.png">
                <div class="cnText">诊所端数据</div>
                <div class="enText"> / CLINIC DATA</div>
            </div>

            <div class="dataLine">
                <div class="textNumber">
                    <div class="text">代理商数量</div>
                    <div class="number">{{ agentNumber }}</div>
                </div>
                <div class="textNumber">
                    <div class="text">城市覆盖率</div>
                    <div class="number">{{ hasClinicCityProportion }}%</div>
                </div>
            </div>

            <div class="title">诊所覆盖数量TOP5省份</div>


            <div class="cityListAndImg">
                <div class="cityList">
                    <div class="city" v-for="(city,index) in top5ClinicCity" :key="'city'+index">{{city.name}} {{city.py}}</div>
                </div>
                <img src="../assets/images/hp.png" style="animation: blink 2s infinite;">
            </div>

        </div>
        <div id="right2" class="modalBgc" :style="{
            zoom:scaleLeft,
        }">
            <div class="broadTitle">
                <img src="../assets/images/titleImg.png">
                <div class="cnText">互联网医院</div>
                <div class="enText"> / INTERNET HOSPITAL</div>
            </div>
            <div class="right2Content">
                <div class="datas">
                    <div class="data data1">
                        <div class="text">问诊量</div>
                        <div class="number">{{ consultationOrderNumber }}</div>
                    </div>

                    <div class="data data2">
                        <div class="text">处方量</div>
                        <div class="number">{{ prescriptionNumber }}</div>
                    </div>
                </div>

                <Drug :data="top10SicknessNumber" style="width: 600px;height: 500px; margin-top: -30px;"> </Drug>
            </div>
        </div>
    </div>
</template>

<script>





import BigMap from "@/components/charts/bigMap.vue";
import ReagentType from "@/components/charts/reagentType.vue";
import Sex from "@/components/charts/sex.vue";
import Age from "@/components/charts/age.vue";
import UserNumber from "@/components/charts/userNumber.vue";
import UserCityNumber from "@/components/charts/userCityNumber.vue";
import Drug from "@/components/charts/drug.vue";
import SumCount from "@/components/charts/sumCount.vue";

export default {
    name:"IndexPage",
    components: {
        SumCount,
        Drug,
        UserCityNumber,
        UserNumber,
        Age,
        Sex,
        ReagentType,
        BigMap

    },
    watch: {
        $route(to , from){
            console.log( to.path, from.path );
            location.reload()
        }

    },
    data() {
        return {
            agentNumber:0,
            hasClinicCityProportion:0,
            top5ClinicCity:[],
            reportNumber:0,
            activeUserNumber:0,
            consultationOrderNumber:0,
            prescriptionNumber:0,
            userNumber:0,
            clinicNumber:0,
            cityUserNumber:[],
            cityClinicNumber:[],
            top10SicknessNumber:[],
            topFiveProvinceUserNumber:[{
                name: '————',
                userNumber: 1,
                py: '————'
            },
            {
                name: '————',
                userNumber:0,
                py: '————'
            },
            {
                name: '————',
                userNumber: 0,
                py: '————'
            },
            {
                name: '————',
                userNumber: 0,
                py: '————'
            },
            {
                name: '————',
                userNumber: 0,
                py: '————'
            }],
            userSexProportion:{
                manProportion:0,
                womanProportion:0
            },
            userAgeProportion:[],
            reagentTypeReportNumber:[]

        }
    },
    mounted: function () {
        // if(!this.timer){
        //     this.timer = setInterval(this.freshTime, 1000);
        // }

        //    ----用户
        //    1.日活用户数量
        this.getActiveUserNumber();
        //    2.日活前五省份和用户数量
        this.getTopFiveProvinceUserNumber();
        // //    3.用户男女比例
        this.getUserSexProportion();
        // //    4.用户年龄比例
        this.getUserAgeProportion();

        // //    ----检测
        // //    5.各检测病种检测数
        this.getReagentTypeReportNumber();
        // //    6.总检测量
        this.getReportNumber();
        //
        // //    ----地图
        // //    7.用户总数
        this.getUserNumber();
        // //    8.诊所总数
        this.getClinicNumber();
        // //    9.城市-用户数
        this.getCityUserNumber();
        // //    10.城市-诊所数
        this.getCityClinicNumber();
        //
        // //    -----诊所
        // //    11.代理商数量
        this.getAgentNumber();
        // //    12.诊所城市覆盖率
        this.getHasClinicCityProportion();
        // //    13.诊所数量TOP5省份
        this.getTop5ClinicCity();
        //
        // //    -----互联网医院
        // //    14.互联网医院问诊量
        this.getConsultationOrderNumber();
        // //    15.互联网医院处方量
        this.getPrescriptionNumber();
        // //    16.互联网医院处方病症数量top10+其他
        this.getTop10SicknessNumber();


    },
    computed: {
        scaleLeft() {
            
            const screenWidth = document.documentElement.clientWidth;
        
            const originalWidth = 780;
            const scale = screenWidth / originalWidth;
            return scale - 0.05;
        },
        scaleCnter() {
            const screenWidth = document.documentElement.clientWidth;
            const originalWidth = 1270;
            const scale = screenWidth / originalWidth;
            return scale - 0.05;
        }
    },
    methods: {
        getActiveUserNumber(){
            let that = this;
            this.ajax("getActiveUserNumber",{},function (ret){
                that.activeUserNumber = ret.data;
            })
        },
        getTopFiveProvinceUserNumber(){
            let that = this;
            this.ajax("getTopFiveProvinceUserNumber",{},function (ret){
                that.topFiveProvinceUserNumber = ret.data;
            })
        },
        getUserSexProportion(){
            let that = this;
            this.ajax("getUserSexProportion",{},function (ret){
                that.userSexProportion = ret.data;
            })
        },
        getUserAgeProportion(){
            let that = this;
            this.ajax("getUserAgeProportion",{},function (ret){
                that.userAgeProportion = ret.data;
            })
        },
        getReagentTypeReportNumber(){
            let that = this;
            this.ajax("getReagentTypeReportNumber",{},function (ret){
                that.reagentTypeReportNumber = ret.data;
            })
        },
        getReportNumber(){
            let that = this;
            this.ajax("getReportNumber",{},function (ret){
                that.reportNumber = ret.data;
            })
        },
        getUserNumber(){
            let that = this;
            this.ajax("getUserNumber",{},function (ret){
                that.userNumber = ret.data;
            })
        },
        getClinicNumber(){
            let that = this;
            this.ajax("getClinicNumber",{},function (ret){
                that.clinicNumber = ret.data;
            })
        },
        getCityUserNumber(){
            let that = this;
            this.ajax("getCityUserNumber",{},function (ret){
                that.cityUserNumber = ret.data;
            })
        },
        getCityClinicNumber(){
            let that = this;
            this.ajax("getCityClinicNumber",{},function (ret){
                that.cityClinicNumber = ret.data;
            })
        },
        getAgentNumber(){
            let that = this;
            this.ajax("getAgentNumber",{},function (ret){
                that.agentNumber = ret.data;
            })
        },
        getHasClinicCityProportion(){
            let that = this;
            this.ajax("getHasClinicCityProportion",{},function (ret){
                that.hasClinicCityProportion = ret.data;
            })
        },
        getTop5ClinicCity(){
            let that = this;
            this.ajax("getTop5ClinicCity",{},function (ret){
                that.top5ClinicCity = ret.data;
            })
        },
        getConsultationOrderNumber(){
            let that = this;
            this.ajax("getConsultationOrderNumber",{},function (ret){
                that.consultationOrderNumber = ret.data;
            })
        },
        getPrescriptionNumber(){
            let that = this;
            this.ajax("getPrescriptionNumber",{},function (ret){
                that.prescriptionNumber = ret.data;
            })
        },
        getTop10SicknessNumber(){
            let that = this;
            this.ajax("getTop10SicknessNumber",{},function (ret){
                that.top10SicknessNumber = ret.data;
            })
        },
        freshTime(){
            // 创建一个 Date 对象
            const today = new Date();

            // 获取年、月、日、时、分、秒
            const year = today.getFullYear();
            const month = today.getMonth() + 1; // 月份是从 0 开始计数的，需要加1
            const day = today.getDate();
            const hours = today.getHours();
            const minutes = today.getMinutes();
            const seconds = today.getSeconds();

            console.log(year,month,day,hours,minutes,seconds);

            //每天晚上00点，刷新页面
            if(hours === 0 && minutes === 0 && seconds === 0){
                window.location.href = location.href;
            }

            //每10秒执行更新
            if (seconds % 10 === 0){
                //    1.日活用户数量
                this.getActiveUserNumber();
                //     6.总检测量
                this.getReportNumber();

                //    7.用户总数
                this.getUserNumber();
                //    8.诊所总数
                this.getClinicNumber();

                //    11.代理商数量
                this.getAgentNumber();

                //    14.互联网医院问诊量
                this.getConsultationOrderNumber();
                //    15.互联网医院处方量
                this.getPrescriptionNumber();

            }

            //每3分钟更新
            if (minutes % 3 === 0){
                //9.城市-用户数
                this.getCityUserNumber();
                //10.城市-诊所数
                this.getCityClinicNumber();
                //5.各检测病种检测数
                this.getReagentTypeReportNumber();
                //2.日活前五省份和用户数量
                this.getTopFiveProvinceUserNumber();

            }

            //每10分钟更新
            if (minutes % 10 === 0){
                //12.诊所城市覆盖率
                this.getHasClinicCityProportion();
                //13.诊所数量TOP5省份
                this.getTop5ClinicCity();

                //16.互联网医院处方病症数量top10+其他
                this.getTop10SicknessNumber();

                //3.用户男女比例
                this.getUserSexProportion();
                //4.用户年龄比例
                this.getUserAgeProportion();
            }

        }

    }

}
</script>
<style lang="scss"  scoped>
    .modalBgc{
        background-color: rgba(0,0,0,.3);
        border-radius: 10px;
        user-select: none;
    }


    ::-webkit-scrollbar {
        display: none;
    }

    html, body {
        overflow: scroll;
    }

    #app, .main, .body {
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        //height: 2560px !important;
        //width: 6144px !important;
        background-image: linear-gradient(to bottom, #070037, #042546 );
        box-sizing: border-box
    }



    .broad{
        // position: fixed;
        //background-color: rgba(0,0,0,.3);
        //border: 1px solid #0bff00;
        .broadTitle{
            display: flex; align-items: center; gap: 10px; ;
            padding: 15px;
            img{
                height: 40px; width: 40px;
            }
            .cnText{
                font-size: 28px; height: 40px; line-height: 40px;
            }
            .enText{
                font-size: 20px; height: 40px; line-height: 45px;
            }
        }

    }
    #index4k{
        padding-top: 50px;
        #left1{
            @extend .broad;
            @extend .modalBgc;
            width: 100%;
            height: 332px;
            left: 85px; top: 70px;
            .left1Content{
                display: flex;
                .title{
                    width: 185px; line-height: 40px;  height: 40px;
                    border: 1px solid #FFF; font-size: 18px;
                    text-align: center; margin: 0 auto; margin-top: 10px;
                }
            }
        }
        #left2{
            @extend .broad;
            height: 368px;
            width: 100%;
            left: 85px; top: 452px;
            .left2Content{
                display: flex;
            }
        }
        #left3{
            @extend .broad;
            height: 352px;
            width: 100%;
            left: 85px; top: 869px;
            .left3Content{
                display: flex;


            }
        }

        #bigTitle{
            @extend .broad;
            width: 90%;
             height: 120px;
            left: 901px; top: 70px;
            .cn{
                font-size: 72px;
                background-image: linear-gradient(to bottom, #49b1fd, #10e3fd );
                -webkit-text-fill-color: transparent;
                -webkit-background-clip: text;
                -webkit-background-size: 200% 100%; height: 70px; line-height: 70px;
                font-weight: bold;
            }
            .en{
                font-size: 24px; margin-top: 20px;
            }
        }
        #centerMap{
            @extend .broad;
            width: 90%; height: 993px;
            left: 901px; top: 228px;
            .mapTitleBg{
                width: 1270px; height: 74px;
                line-height: 74px; background-repeat: no-repeat;
                background-position:  center; color: #FFF; font-size: 34px;
                letter-spacing: 12px;
                background-image: url(../assets/images/mapTitleBg.png);
                margin: 20px 0;
            }
        }

        #right1{
            @extend .broad;
            width: 100%;
            height: 578px;
            left: 2227px; top: 70px;

            .dataLine{
                display: flex; margin-top: 20px;
                .textNumber{
                    flex: 1;
                }
                .text{
                    font-size: 18px;
                }
                .number{
                    font-size: 56px;
                }
            }
            .title{
                width: 225px; line-height: 40px;  height: 40px;
                border: 1px solid #FFF; font-size: 18px;
                text-align: center; margin-top: 30px; margin-left: 50px;
            }

            .cityListAndImg{
                display: flex; padding: 30px 20px;
                .cityList{
                    width: 150px; text-align: left; margin-top: 10px;
                    line-height:50px; padding: 0 30px;
                    .city{

                    }
                }

                img{
                    width: 505px; margin-right: 15px;
                }
            }



        }
        #right2{
            @extend .broad;
            width: 100%;
             height: 522px;
            left: 2227px; top: 699px;

            .right2Content{
                display: flex;
                .datas{
                    /* 定义动画 */



                    .data{
                        width: 170px; height: 70px; padding: 50px 0; text-align: center;
                        background-position: center; background-repeat: no-repeat;
                        position: relative;
                        z-index: 1;

                        margin: 30px 40px;
                        .text{
                            font-size: 18px;
                        }
                        .number{
                            font-size: 39px;
                        }
                    }
                    .data::before{
                        z-index: -1;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 170px; height: 170px;
                        animation: rotateBackground .8s linear infinite; /* 动画名称 持续时间 速率 重复次数 */
                    }

                    .data1::before{

                        background-image: url("../assets/images/c1.png");

                    }
                    .data2::before{
                        background-image: url("../assets/images/c2.png");
                    }

                }
            }




        }
    }






</style>

