<template>
    <div style="position: relative">
        <div  id="aaa" style="width: 1155px; height:880px;">
            <div id="map" style="width: 1155px; height:880px;"></div>
        </div>

        <div class="bottomNumber" style="left: 10px">
            <div class="line">
                <div class="point" style="background:#f565c4"></div>
                <div class="cn">用户分布</div>
                <div class="en">ONLINE USER</div>
            </div>
            <div class="number">{{ userNumber }}</div>

            <img src="../../assets/images/line2.png" />
        </div>


        <div class="bottomNumber" style="right: 10px">
            <div class="line">
                <div class="point" style="background:#8962ff"></div>
                <div class="cn" @click="setMapType()">诊所分布</div>
                <div class="en">OFFLINE CLINIC</div>
            </div>
            <div class="number">{{ clinicNumber }}</div>
            <img src="../../assets/images/line2.png" />
        </div>
    </div>

</template>

<script>

import * as echarts from 'echarts';

import geoJsonWorld from '../../assets/json/world.zh.json'

import geoJsonCN from '../../assets/json/china.json'



let myChart;
export default {
    name: "bigMap",
    props:{
        userNumber:{
            type:Number,
            default(){
                return 0;
            }
        },
        clinicNumber:{
            type:Number,
            default(){
                return 0;
            }
        },
        userMapData:{
            type:Array,
            default(){
                return [];
            }
        },
        clinicMapData:{
            type:Array,
            default(){
                return [];
            }
        }
    },
    watch:{
        userMapData(){
            this.createChart();
        },
        clinicMapData(){
            this.createChart();
        }
    },
    data(){
        return{
            mapType:"CN",
            locationIndex:0,
            locationWorld:[{
                lat:33,
                lng:105,
                zoom:5
            },{
                lat:43,
                lng:115,
                zoom:7
            },{
                lat:23,
                lng:115,
                zoom:7
            },{
                lat:38,
                lng:95,
                zoom:7
            }],
            locationCN:[{
                lat:33,
                lng:105,
                zoom:1.5
            },{
                lat:43,
                lng:115,
                zoom:2.3
            },{
                lat:23,
                lng:115,
                zoom:2.3
            },{
                lat:38,
                lng:95,
                zoom:2.3
            }],
            map:null,
            BMap:null
        }
    },
    mounted() {
        this.createChart();
    },
    methods:{
        setMapType(){
            console.log("setMapType",this.mapType)
            if (this.mapType==="CN"){
                this.mapType="WORLD";
            }else{
                this.mapType="CN"
            }

            myChart.dispose();
            myChart = null;
            this.createChart();
        },
        createChart(){
            let that = this;
            const chartDom = document.getElementById('map');
            myChart = echarts.init(chartDom );
            if (this.mapType==="CN"){
                echarts.registerMap('CN', geoJsonCN);
            }else{
                echarts.registerMap('CN', geoJsonWorld);
            }

            const option = {
                geo: {
                    map: 'CN',
                    nameProperty: 'name', // 数据点中的 name：Alabama 会关联到 GeoJSON 中 NAME 属性值为 Alabama 的地理要素{"type":"Feature","id":"01","properties":{"NAME":"Alabama"}, "geometry": { ... }}
                    roam: true, //可平移，缩放
                    itemStyle: {
                        areaColor: '#004981',
                        borderColor : '#029fd4',
                        borderWidth: 1
                    },
                    scaleLimit:{
                        min:1,
                        max:12
                    },
                    zoom: 5,
                    center: [105, 33]
                },
                backgroundColor: 'transparent',

                // title: {
                //     text: 'Population Density of Hong Kong （2011）',
                //         subtext: 'Data from Wikipedia',
                //         sublink:
                //     'http://zh.wikipedia.org/wiki/%E9%A6%99%E6%B8%AF%E8%A1%8C%E6%94%BF%E5%8D%80%E5%8A%83#cite_note-12'
                // },
                tooltip: {
                    trigger: 'item',
                        formatter: '{b}<br/>{c} (p / km2)'
                },
                series: [
                    {
                        name: '用户',
                        type: 'scatter',
                        color:"#f565c4",
                        coordinateSystem: 'geo',
                        data: this.convertMapData(this.userMapData),
                        symbolSize: function (val) {
                            return val[2]*3;
                        },
                        encode: {
                            value: 2
                        },
                        label: {
                            formatter: '{b}',
                            position: 'right',
                            show: false
                        },
                        emphasis: {
                            label: {
                                show: true
                            }
                        }
                    },
                    {
                        name: '诊所',
                        // type:'scatter',
                        type: 'effectScatter',
                        color:"#8962ff",
                        coordinateSystem: 'geo',
                        data: this.convertMapData( this.clinicMapData ),
                        symbolSize: function (val) {
                            return val[2]*5;
                        },
                        encode: {
                            value: 2
                        },
                        showEffectOn: 'render',
                        rippleEffect: {
                            brushType: 'stroke'
                        },
                        label: {
                            fontSize: 20,
                            formatter: '{b}',
                            position: 'right',
                            show: false
                        },
                        itemStyle: {
                            shadowBlur: 20,
                            shadowColor: '#666'
                        },
                        emphasis: {
                            scale: true
                        },
                        zlevel: 1
                    }
                ]
            };

            myChart.setOption(option);
            // this.map = myChart.getModel().getComponent('bmap').getBMap()
            //
            // myChart._model.eachComponent('bmap', function (bmapModel) {
            //     if(that.BMap == null){
            //         that.BMap = bmapModel.__bmap;
            //     }
            // });
            //
            if (!this.timer){
                this.timer = setInterval(function (){
                    that.changeMapLocation();
                },5000)
            }


        },
        changeMapLocation(){
            console.log('changeMapLocation',this.locationIndex)


            if (this.mapType==="CN"){
                myChart.setOption({
                    geo: {
                        center: [this.locationCN[this.locationIndex].lng, this.locationCN[this.locationIndex].lat],
                        zoom:this.locationCN[this.locationIndex].zoom
                    },
                });

                if(this.locationIndex === this.locationCN.length-1){
                    this.locationIndex = 0;
                }else{
                    this.locationIndex++;
                }

            }else{
                myChart.setOption({
                    geo: {
                        center: [this.locationWorld[this.locationIndex].lng, this.locationWorld[this.locationIndex].lat],
                        zoom:this.locationWorld[this.locationIndex].zoom
                    },
                });

                if(this.locationIndex === this.locationWorld.length-1){
                    this.locationIndex = 0;
                }else{
                    this.locationIndex++;
                }
            }




            // var center = this.map.getCenter();
            // center.lat = this.locations[this.locationIndex].lat;
            // center.lng = this.locations[this.locationIndex].lng;
            // this.map.setZoom(this.locations[this.locationIndex].zoom);
            // this.map.panTo(center);


            // this.map.setZoom(10)

            // myChart.dispatchAction({
            //     type: 'highlight',
            //     seriesIndex: 0,
            //     name: '山东省'
            // })
        },
        convertMapData(data){
            var res = [];
            console.log(data);
            for (var i = 0; i < data.length; i++) {
                res.push({
                    name: data[i].name,
                    value:[
                        data[i].lon,
                        data[i].lat,
                        data[i].userNumber
                    ]
                })
            }

            console.log("res",res)
            return res;
        }
    }
}
</script>

<style lang="scss" scoped>
    .bottomNumber{
        position: absolute;bottom:10px;
        background: rgba(0,0,0,.6); width: 340px; height: 95px; border-radius: 10px;
        //box-shadow: 1px 1px 10px 1px #272727;
        padding: 15px;


        .line{
            display: flex; align-items: center; gap: 10px;
            .point{
                width: 24px; height: 24px; border-radius: 30px;
            }
            .cn{
                font-size: 20px; height: 24px; line-height:24px;
            }
            .en{
                font-size: 17px; height: 24px;line-height:24px;
            }

        }

        .number{
            font-size: 39px; margin-top: 10px; margin-bottom: 5px;
        }


    }
</style>
