import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
axios.defaults.withCredentials=false;

Vue.config.productionTip = false

//开发
// const apiUrl = "http://localhost:8081/DataPanel/";

//测试
// const apiUrl = "https://testapi.jucelife.com/DataPanel/";

//生产
const apiUrl = "https://api.jucelife.com/DataPanel/";

Vue.prototype.ajax = function(url,pa,callback){


	const params = new URLSearchParams();

	for (const sProp in pa) {
		params.append(sProp,pa[sProp]);
	}


	const allUrl = apiUrl + url;

	axios.post(allUrl, params).then(({data}) => {
		if (data.isSuccess == 0) {
			// alert(data.msg);
		} else {
			if (typeof (callback) == "function") {
				callback(data);
			}
		}
	})
}


new Vue({
	render: h => h(App),
}).$mount('#app')
