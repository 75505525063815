<template>
    <div >
        <div class="title"> 女性占比  <span>{{ woman }}%</span> </div>
        <div class="imgs">
            <img style="animation: blink 2s infinite;" src="../../assets/images/womanActive.png" v-for="index of Math.round(woman/10) " :key="index+300">
            <img src="../../assets/images/womanNormal.png" v-for="index of 10-Math.round(woman/10) " :key="index+400">
        </div>

        <div class="title"> 男性占比  <span>{{ man }}%</span> </div>
        <div class="imgs">
            <img style="animation: blink 2s infinite;" src="../../assets/images/manActive.png" v-for="index of Math.round(man/10) " :key="index+100">
            <img src="../../assets/images/manNormal.png" v-for="index of 10-Math.round(man/10) " :key="index+200">
        </div>
    </div>
</template>

<script>
export default {
    name: "sexC",
    props:{
        woman:{
            type:Number,
            default:79
        },
        man:{
            type:Number,
            default:21
        }
    }
}
</script>

<style lang="scss" scoped>
    .title{
        font-size: 18px;
        margin-top: 20px;
        span{
            font-size: 28px;
        }
    }
    .imgs{
        margin-top: 10px;
    }
</style>
