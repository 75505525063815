var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"body",attrs:{"id":"index4k"}},[_c('div',{style:({
        zoom:_vm.scaleCnter
    }),attrs:{"id":"bigTitle"}},[_c('div',{staticClass:"cn"},[_vm._v("居测智慧云数字医疗中心")]),_c('div',{staticClass:"en"},[_vm._v("HOME DETECTION SMART CLOUD DIGITAL MEDICAL CENTER")])]),_c('div',{staticClass:"modalBgc",style:({
        zoom:_vm.scaleCnter
    }),attrs:{"id":"centerMap"}},[_c('div',{staticClass:"mapTitleBg modalBgc"},[_vm._v(" 全国用户分布图 ")]),_c('BigMap',{staticStyle:{"width":"1155px","height":"831px","overflow":"hidden","margin":"0 auto"},attrs:{"userMapData":_vm.cityUserNumber,"clinic-map-data":_vm.cityClinicNumber,"userNumber":_vm.userNumber,"clinicNumber":_vm.clinicNumber}})],1),_c('div',{staticClass:"modalBgc",style:({
        zoom:_vm.scaleLeft,
    }),attrs:{"id":"left1"}},[_vm._m(0),_c('div',{staticClass:"left1Content"},[_c('userNumber',{staticStyle:{"width":"270px"},attrs:{"number":_vm.activeUserNumber}}),_c('img',{staticStyle:{"margin-left":"-10px","height":"130px","margin-top":"50px"},attrs:{"src":require("../assets/images/line1.png")}}),_c('div',{staticStyle:{"width":"568px"}},[_c('div',{staticClass:"title"},[_vm._v("用户活跃TOP5省份")]),_c('userCityNumber',{staticStyle:{"width":"400px","height":"280px","margin-top":"-40px","margin-left":"50px"},attrs:{"data":_vm.topFiveProvinceUserNumber}})],1)],1)]),_c('div',{staticClass:"modalBgc",style:({
        zoom:_vm.scaleLeft,
    }),attrs:{"id":"left2"}},[_vm._m(1),_c('div',{staticClass:"left2Content"},[_c('Sex',{staticStyle:{"width":"380px","height":"280px"},attrs:{"woman":_vm.userSexProportion.womanProportion,"man":_vm.userSexProportion.manProportion}}),_c('Age',{staticStyle:{"width":"460px","height":"280px"},attrs:{"data":_vm.userAgeProportion}})],1)]),_c('div',{staticClass:"modalBgc",style:({
        zoom:_vm.scaleLeft,
    }),attrs:{"id":"left3"}},[_vm._m(2),_c('div',{staticClass:"left3Content"},[_c('ReagentType',{staticStyle:{"width":"560px","height":"360px","margin-top":"-50px","margin-left":"-20px"},attrs:{"data":_vm.reagentTypeReportNumber}}),_c('SumCount',{staticStyle:{"min-width":"120px","height":"200px"},attrs:{"reportNumber":_vm.reportNumber}})],1)]),_c('div',{staticClass:"modalBgc",style:({
        zoom:_vm.scaleLeft,
    }),attrs:{"id":"right1"}},[_vm._m(3),_c('div',{staticClass:"dataLine"},[_c('div',{staticClass:"textNumber"},[_c('div',{staticClass:"text"},[_vm._v("代理商数量")]),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.agentNumber))])]),_c('div',{staticClass:"textNumber"},[_c('div',{staticClass:"text"},[_vm._v("城市覆盖率")]),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.hasClinicCityProportion)+"%")])])]),_c('div',{staticClass:"title"},[_vm._v("诊所覆盖数量TOP5省份")]),_c('div',{staticClass:"cityListAndImg"},[_c('div',{staticClass:"cityList"},_vm._l((_vm.top5ClinicCity),function(city,index){return _c('div',{key:'city'+index,staticClass:"city"},[_vm._v(_vm._s(city.name)+" "+_vm._s(city.py))])}),0),_c('img',{staticStyle:{"animation":"blink 2s infinite"},attrs:{"src":require("../assets/images/hp.png")}})])]),_c('div',{staticClass:"modalBgc",style:({
        zoom:_vm.scaleLeft,
    }),attrs:{"id":"right2"}},[_vm._m(4),_c('div',{staticClass:"right2Content"},[_c('div',{staticClass:"datas"},[_c('div',{staticClass:"data data1"},[_c('div',{staticClass:"text"},[_vm._v("问诊量")]),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.consultationOrderNumber))])]),_c('div',{staticClass:"data data2"},[_c('div',{staticClass:"text"},[_vm._v("处方量")]),_c('div',{staticClass:"number"},[_vm._v(_vm._s(_vm.prescriptionNumber))])])]),_c('Drug',{staticStyle:{"width":"600px","height":"500px","margin-top":"-30px"},attrs:{"data":_vm.top10SicknessNumber}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"broadTitle"},[_c('img',{attrs:{"src":require("../assets/images/titleImg.png")}}),_c('div',{staticClass:"cnText"},[_vm._v("用户数据")]),_c('div',{staticClass:"enText"},[_vm._v(" / USER DATA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"broadTitle"},[_c('img',{attrs:{"src":require("../assets/images/titleImg.png")}}),_c('div',{staticClass:"cnText"},[_vm._v("用户画像")]),_c('div',{staticClass:"enText"},[_vm._v(" / USER PORTRAIT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"broadTitle"},[_c('img',{attrs:{"src":require("../assets/images/titleImg.png")}}),_c('div',{staticClass:"cnText"},[_vm._v("检测病种")]),_c('div',{staticClass:"enText"},[_vm._v(" / Detection of disease")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"broadTitle"},[_c('img',{attrs:{"src":require("../assets/images/titleImg.png")}}),_c('div',{staticClass:"cnText"},[_vm._v("诊所端数据")]),_c('div',{staticClass:"enText"},[_vm._v(" / CLINIC DATA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"broadTitle"},[_c('img',{attrs:{"src":require("../assets/images/titleImg.png")}}),_c('div',{staticClass:"cnText"},[_vm._v("互联网医院")]),_c('div',{staticClass:"enText"},[_vm._v(" / INTERNET HOSPITAL")])])
}]

export { render, staticRenderFns }